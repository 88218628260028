import {Component, EventEmitter, Input, NgModule, OnInit, Output} from '@angular/core';
import {ButtonColor, ButtonType} from "@shared/enum";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'qlive-button',
  templateUrl: './qlive-button.component.html',
  styleUrls: ['./qlive-button.component.scss']
})
export class QliveButtonComponent implements OnInit {

  @Input()
  buttonType: ButtonType = ButtonType.REGULAR;

  @Input()
  color: ButtonColor = ButtonColor.PRIMARY;

  @Output() onClick = new EventEmitter<any>();

  @Input() toolTip: string = '';
  @Input() loading = false;
  @Input() disabled = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}

@NgModule({
  declarations: [QliveButtonComponent],
  imports: [CommonModule, MatButtonModule, MatTooltipModule, MatProgressSpinnerModule],
  exports: [QliveButtonComponent]
})
export class QliveButtonComponentModule {
}
