export const environment = {
  firebase: {
    apiKey: "AIzaSyD7ydJVU_853_oZd_tRfzTSyHwkxhedpnU",
    authDomain: "prod-qlive.firebaseapp.com",
    projectId: "prod-qlive",
    storageBucket: "prod-qlive.appspot.com",
    messagingSenderId: "123597455677",
    appId: "1:123597455677:web:1d73e4910d5e7340e4c554",
    measurementId: "G-0ZFZWGQG8T"
  },
  api: {
    url: 'https://api.qlivelearn.in'
  },
  disableResetMail: true,
  production: true
};
