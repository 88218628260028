import {Component} from '@angular/core';
import {animateText, onSideNavChange} from "@shared/animations/animations";
import {SideNavService} from "@shared/services/side-nav.service";
import {AuthService} from "@pages/auth/auth.service";
import {Router} from "@angular/router";


interface Page {
  link: string;
  name: string;
  icon: string;
}

const linkTemplates = {
  admin : [
    {name: 'Dashboard', icon: 'dashboard', routerLink: '/admin/dashboard'},
    {name: 'Students', icon: 'groups', routerLink: '/admin/student'},
    {name: 'Teachers', icon: 'supervisor_account', routerLink: '/admin/teacher'},
    {name: 'Assessment', icon: 'assessment', routerLink: '/admin/assessment/list'},
    {name: 'Payment', icon: 'account_balance_wallet', routerLink: '/admin/payment'},
    {name: 'Accounts', icon: 'account_balance', routerLink: '/admin/accounts'},
  ], staff: [
    {name: 'Students', icon: 'groups', routerLink: '/admin/student'},
    {name: 'Teachers', icon: 'supervisor_account', routerLink: '/admin/teacher'},
    {name: 'Assessment', icon: 'assessment', routerLink: '/admin/assessment/list'},
    {name: 'Payment', icon: 'account_balance_wallet', routerLink: '/admin/payment'},
  ]
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  animations: [onSideNavChange, animateText]
})
export class LeftMenuComponent {


  public sideNavState: boolean = false;
  public linkText: boolean = false;

  public pages: Page[] = [
    {name: 'Inbox', link: 'some-link', icon: 'inbox'},
    {name: 'Starred', link: 'some-link', icon: 'star'},
    {name: 'Send email', link: 'some-link', icon: 'send'},
  ]

  links = {
    admin: [],
    common: [
      {name: 'Settings', icon: 'settings', routerLink: '/admin/teacher'},
      {name: 'Help', icon: 'help', routerLink: '/admin/teacher'},
      {name: 'Logout', icon: 'logout', routerLink: '/auth/login'},
    ]
  }

  constructor(private _sidenavService: SideNavService,
              public router: Router,
              private authService: AuthService) {
    this._sidenavService.sideNavState$.subscribe(res => {
      this.onSidenavToggle(res)
    })
    this.authService.userRole.subscribe(auth => {
      if(auth.isStaff && !(auth.isAdmin)) {
        this.links.admin = linkTemplates.staff
      } else {
        this.links.admin = linkTemplates.admin
      }
    })
  }

  async clickHandler(link: { routerLink: string; name: string; icon: string }) {
    if (link.name === 'Logout') {
      await this.authService.logout()
    } else {
      await this.router.navigate([link.routerLink])
    }
  }


  onSidenavToggle(res: boolean) {
    this.sideNavState = res
    setTimeout(() => {
      this.linkText = this.sideNavState;
    }, 200)
  }

  getImagePath() {
    return `./assets/img/${(!this.linkText ? 'q-live-mini-icon.webp' : 'q-live-logo.webp')}`
  }
}
