import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from "@angular/forms";

@Component({
  selector: 'form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  errorMsgList: any = [];

  @Input() controlName: AbstractControl | AbstractControlDirective
  @Output() invalid = new EventEmitter<boolean>()
  errorMessage = {
    'required': (params) => `This field is required`,
    'email': (params) => `Email is invalid`,
    'maxlength': (params) => `Maximum ${params.requiredLength} characters are allowed`,
    'minlength': (params) => `Minimum ${params.requiredLength} characters are required`,
    'pattern': (params) => `Invalid format`,
    'min': (params) => `Minimum amount should be ₹ ${params.min}`,
    'whitespace': (params) => `White spaces are not allowed`
  };


  listErrors() {
    if (this.controlName && this.controlName.errors) {
      this.errorMsgList = [];
      Object.keys(this.controlName.errors).map(error => {
        this.controlName.touched || this.controlName.dirty ?
          this.errorMsgList.push(this.errorMessage[error](this.controlName.errors[error])) : '';
      });
      if (this.errorMsgList.length > 0) {
        this.invalid.emit(true)
      }
      return this.errorMsgList;
    } else {
      this.invalid.emit(false)
      return [];

    }
  }

}
