import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>`,
  styles: [
    `:host {
      @apply block w-screen h-screen overflow-auto
    }`
  ]
})
export class AppComponent implements OnInit {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private router: Router,
  ) {
    const svgIcons = ['edit', 'delete', 'delete-red', 'teacher',
      'book', 'error-cross', 'confirm', 'payment-confirm',
      'view-log', 'assignments', 'copy-link', 'see-results',
      'browse-image','arrow-forward', 'print']
    svgIcons.forEach(svgIcon => {
      this.matIconRegistry.addSvgIcon(`qlive-${svgIcon}`, this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/svg-icons/qlive-${svgIcon}.svg`))
    })
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (location.hash === '#/') {
          this.router.navigate(['admin/dashboard'])
        }
      }
    })
  }

  ngOnInit(): void {
  }
}
