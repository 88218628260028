<div class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'">
  <div class="sidenav-wrapper">
    <div class="h-64 flex place-content-center">
      <img class="mt-16 h-24" [src]="getImagePath()" alt="Qlive Logo">
    </div>
    <div class="links">
      <div class="role-links">
        <mat-nav-list>
          <a *ngFor="let link of (links['admin'])" mat-list-item [routerLink]="link.routerLink"
             routerLinkActive="active-link">
            <mat-icon>{{link.icon}}</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{ link.name }} </span>
          </a>
        </mat-nav-list>
      </div>
      <div class="common-links">
        <mat-nav-list class="p-0">
          <a *ngFor="let link of links['common']" mat-list-item  (click)="clickHandler(link)">
            <mat-icon>{{link.icon}}</mat-icon>
            <span [@animateText]="linkText ? 'show' : 'hide'">{{ link.name }} </span>
          </a>
        </mat-nav-list>
      </div>
    </div>
  </div>
</div>
