import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {ChoiceListState} from "./choice-list.reducer";
import {loadChoiceList} from "./choice-list.actions";
import {ChoiceListType} from "@shared/enum";
import {choiceListQuery} from "./choice-list.selectors";

@Injectable()
export class ChoiceListFacade {
  grade$ = this.store.select(choiceListQuery.getGrade)
  syllabus$ = this.store.select(choiceListQuery.getSyllabus)
  studentType$ = this.store.select(choiceListQuery.getStudentType)
  qualification$ = this.store.select(choiceListQuery.getQualification)
  language$ = this.store.select(choiceListQuery.getLanguage)
  subject$ = this.store.select(choiceListQuery.getSubject)
  paymentMethod$ = this.store.select(choiceListQuery.getPaymentMethod)
  paymentAccount$ = this.store.select(choiceListQuery.getPaymentAccount)
  difficulty$ = this.store.select(choiceListQuery.getDifficulty)
  assessmentTheme$ = this.store.select(choiceListQuery.getAssessmentTheme)
  getEnquiryCategory$ = this.store.select(choiceListQuery.getEnquiryCategory)

  staff$ = this.store.select(choiceListQuery.getStaff)


  isLoaded = {
    grade: false,
    syllabus: false,
    type: false,
    qualification: false,
    language: false,
    subjects: false,
    paymentMethod: false,
    paymentAccount: false,
    difficulty: false,
    assessmentTheme: false,
    enquiryCategory: false,
    staff: false,
  }

  constructor(private store: Store<ChoiceListState>) {
  }


  loadGrade() {
    if (!this.isLoaded.grade) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.GRADE}))
      this.isLoaded.grade = true
    }
  }

  loadSyllabus() {
    if (!this.isLoaded.syllabus) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.SYLLABUS}))
      this.isLoaded.syllabus = true
    }
  }

  loadStudentType() {
    if (!this.isLoaded.type) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.STUDENT_TYPE}))
      this.isLoaded.type = true
    }
  }

  loadQualification() {
    if (!this.isLoaded.qualification) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.QUALIFICATION}))
      this.isLoaded.qualification = true
    }
  }

  loadLanguage() {
    if (!this.isLoaded.language) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.LANGUAGE}))
      this.isLoaded.language = true
    }
  }

  loadSubjects() {
    if (!this.isLoaded.subjects) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.SUBJECT}))
      this.isLoaded.subjects = true
    }
  }

  loadPaymentMethod() {
    if (!this.isLoaded.paymentMethod) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.PAYMENT_METHOD}))
      this.isLoaded.paymentMethod = true
    }
  }

  loadPaymentAccount() {
    if (!this.isLoaded.paymentAccount) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.PAYMENT_ACCOUNT}))
      this.isLoaded.paymentAccount = true
    }
  }

  loadDifficulty() {
    if (!this.isLoaded.difficulty) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.DIFFICULTY}))
      this.isLoaded.difficulty = true
    }
  }

  loadAssessmentTheme() {
    if (!this.isLoaded.assessmentTheme) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.ASSESSMENT_THEME}))
      this.isLoaded.assessmentTheme = true
    }
  }
  loadEnquiryCategory() {
    if (!this.isLoaded.enquiryCategory) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.ENQUIRY_CATEGORY}))
      this.isLoaded.enquiryCategory = true
    }
  }


  loadStaff() {
    if (!this.isLoaded.staff) {
      this.store.dispatch(loadChoiceList({choiceListType: ChoiceListType.STAFF}))
      this.isLoaded.staff = true
    }
  }

}
