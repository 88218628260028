export enum ChoiceListType {
  SYLLABUS = 'SYLLABUS',
  STUDENT_TYPE = 'STUDENT_TYPE',
  GRADE = 'GRADE',
  LANGUAGE = 'LANGUAGE',
  SUBJECT = 'SUBJECT',
  QUALIFICATION = 'QUALIFICATION',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
  PAYMENT_MONTH = 'PAYMENT_MONTH',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT',
  DIFFICULTY = 'DIFFICULTY',
  ASSESSMENT_THEME = 'ASSESSMENT_THEME',
  ENQUIRY_CATEGORY = 'ENQUIRY_CATEGORY',
  ASSIGNEE_LIST = 'ASSIGNEE_LIST',
  ASSIGNED_SESSION = 'ASSIGNED_SESSION',
  STAFF = 'STAFF',

}
