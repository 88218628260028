import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellComponent} from "@shared/shell/shell.component";
import {LayoutModule} from "@angular/cdk/layout";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {RouterModule} from "@angular/router";
import {MatBadgeModule} from "@angular/material/badge";
import {LeftMenuComponent} from './layout/left-menu/left-menu.component';
import {ConfirmModalComponent} from './modals/confirm-modal/confirm-modal.component';
import {QliveButtonComponentModule} from "@shared/components/qlive-button/qlive-button.component";
import {MatDialogModule} from "@angular/material/dialog";
import {FormErrorComponent} from './components/form-error/form-error.component';

const COMPONENTS = [ShellComponent, FormErrorComponent
]


const MODULES = [
  CommonModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  LayoutModule,
  MatSidenavModule,
  MatListModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  RouterModule,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    LeftMenuComponent,
    ConfirmModalComponent,
  ],
  imports: [
    ...MODULES,
    MatBadgeModule,
    QliveButtonComponentModule,
    MatDialogModule
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES,
  ]
})
export class SharedModule {
}
