import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {User} from "@shared/model";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {environment} from "@environment/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authForm: FormGroup
  type: 'login' | 'forgot' | 'reset' = 'login';
  loading = false;

  serverMessage: string = '';

  passwordToggle = {
    isVisibleLoginPassword: false,
    isVisibleNewPassword: false,
    isVisibleConfirmPassword: false,
  }
  currentUser: User;

  constructor(private afAuth: AngularFireAuth,
              private fb: FormBuilder,
              private afs: AngularFirestore,
              private route: ActivatedRoute,
              public router: Router,
              private toastr: ToastrService,
  ) {
    this.authForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.minLength(6), Validators.required]
      ],
      passwordConfirm: ['', []]
    })
    this.afAuth.idToken.subscribe(token => localStorage.setItem('token', token))
  }

  get userAuthState() {
    return this.afAuth.authState
      .pipe(switchMap(user => user ? this.afs.doc<User>(`users/${user.uid}`).valueChanges() : of(null)));
  }

  get userRole() {
    return this.userAuthState.pipe(
      map(auth => {
console.log(auth.roles, "roles")
return ({
        isAdmin: 'adminId' in auth.roles,
        isTeacher: 'teacherId' in auth.roles,
        isStaff: 'staffId' in auth.roles,
      })}
				)
    )
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isForgotPassword() {
    return this.type === 'forgot';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get email() {
    return this.authForm.get('email') as FormControl;
  }

  get password() {
    return this.authForm.get('password') as FormControl;
  }

  get passwordConfirm() {
    return this.authForm.get('passwordConfirm') as FormControl;
  }

  get passwordDoesMatch() {
    if (this.type !== 'reset') {
      return true;
    } else {
      return this.password?.value === this.passwordConfirm?.value;
    }
  }


  async onSubmit() {
    this.loading = true;

    const email = this.email?.value;
    const password = this.password?.value;

    try {
      if (this.isLogin) {
        let userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
        await this.router.navigate([''])
      }
      if (this.isForgotPassword) {
        await this.sendResetMail(email)
        this.toastr.info('Check your email')
        await this.router.navigate(['/auth/login'])
      }
      if (this.isPasswordReset) {
        await this.afAuth.sendPasswordResetEmail(email);
      }
    } catch (err) {
      this.toastr.error(this.getErrorMessage(err['code']))
      this.serverMessage = err;
    }

    this.loading = false;
  }

  getErrorMessage(code: string) {
    switch (code) {
      case 'auth/user-disabled':
        return 'Sorry your user is disabled.';
      case 'auth/user-not-found':
        return 'Sorry user not found.';
      case 'auth/invalid-email':
        return 'Invalid email.'
      default: {
        return 'Login error try again later.';
      }
    }
  }


  updateUserData({uid, name, email, adminId = undefined, teacherId = undefined, parentId = undefined}) {
    const userRef = this.afs.doc(`users/${uid}`);
    const data: User = {
      uid,
      name,
      email,
      roles: {
        ...(adminId && {adminId}),
        ...(teacherId && {teacherId}),
        ...(parentId && {parentId}),
      }
    }
    return userRef.set(data, {merge: true})
  }

  async registerANewUser(email) {
    const password = environment.disableResetMail ? 'test123' : this.generatePassword()
    let currentUser = await this.afAuth.currentUser;
    const userCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
    await this.afAuth.updateCurrentUser(currentUser)
    return userCredential.user.uid
  }

  async logout() {
    await this.afAuth.signOut()
    this.currentUser = null
    await this.router.navigate(['/auth/login'])
  }

  async sendResetMail(email) {
    await this.afAuth.sendPasswordResetEmail(email);
  }

  async checkResetLinkIsValid() {
    const {oobCode} = this.route.snapshot.queryParams
    if (oobCode) {
      try {
        let email = await this.afAuth.verifyPasswordResetCode(oobCode);
        this.email.setValue(email)
      } catch (e) {
        await this.router.navigate(['/auth/login'])
        this.toastr.warning('Invalid password reset link')
      }
    } else {
      await this.router.navigate(['/auth/login'])
    }
  }

  async confirmPasswordReset() {
    const {oobCode} = this.route.snapshot.queryParams
    if (oobCode) {
      try {
        if (this.passwordConfirm.value === this.password.value) {
          let email = await this.afAuth.confirmPasswordReset(oobCode, this.passwordConfirm.value);
          this.password.setValue('')
          this.toastr.success('Password reset successful. Please login')
        } else {
          this.toastr.error("Passwords doesn't match")
        }
      } catch (e) {
        this.toastr.error('Failed to reset password. Please try again later')

      } finally {
        await this.router.navigate(['/auth/login'])

      }
    } else {
      await this.router.navigate(['/auth/login'])
    }
  }


  generatePassword(): string {
    let length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
}
