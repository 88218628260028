import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {map} from "rxjs/operators";
import {AuthService} from "@pages/auth/auth.service";
import {User} from "@shared/model";
import {Role} from "@shared/enum";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private afAuth: AngularFireAuth,
              private router: Router,
              private authService: AuthService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const {role} = route.data
    return this.authService.userAuthState
      .pipe(
        map(auth => {
          if (!auth) {
            this.router.navigate(['/auth/login']);
            return false
          } else if (!this.hasMatchingRole(auth, role)) {
            this.navigateToUserDashboard(auth)
            return false
          } else {
            this.authService.currentUser = auth
            return true
          }
        })
      )
  }

  private hasMatchingRole(auth: User, role: Role) {
    switch (role) {
      case Role.ADMIN:
        return 'adminId' in auth.roles || 'staffId' in auth.roles
      case Role.TEACHER:
        return 'teacherId' in auth.roles
      case Role.PARENT:
        return 'parentId' in auth.roles
      case Role.STAFF:
        return 'staffId' in auth.roles
      default:
        return false
    }
  }

  private navigateToUserDashboard(user: User) {
    const {parentId, teacherId, adminId, staffId} = user.roles
    if (teacherId) {
      this.router.navigate(['teacher/dashboard'])
    }
    if (adminId) {
      this.router.navigate(['admin/dashboard'])
    }
    if (parentId) {
      this.router.navigate(['parent/dashboard'])
    }
    if (staffId) {
      this.router.navigate(['staff/dashboard'])
    }
  }
}
