import {createAction, props} from '@ngrx/store';
import {ChoiceListType} from "@shared/enum";

export const loadChoiceList = createAction(
  '[ChoiceListFacade] Load ChoiceList',
  props<{ choiceListType: ChoiceListType }>()
);

export const loadChoiceListSuccess = createAction(
  '[ChoiceListFacade] Load ChoiceList Success',
  props<{ data: any[], choiceListType: ChoiceListType  }>()
);

export const loadChoiceListFailure = createAction(
  '[ChoiceListFacade] Load ChoiceList Failure',
  props<{ error: any }>()
);
