import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import * as ChoiceListActions from './choice-list.actions';
import {ApiService} from "@shared/services/api.service";
import {ChoiceListType} from "@shared/enum";
import {HttpParams} from "@angular/common/http";
import {ChoiceList} from "@shared/model";


@Injectable()
export class ChoiceListEffects {

  loadChoiceLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChoiceListActions.loadChoiceList),
      concatMap(({choiceListType}) =>
        this.apiService.get('choice-list', this.getChoiceListParam(choiceListType)).pipe(
          map((data: ChoiceList[]) => {
            return ChoiceListActions.loadChoiceListSuccess({data, choiceListType});
          }),
          catchError(error => of(ChoiceListActions.loadChoiceListFailure({error}))))
      )
    );
  });

  getChoiceListParam(choiceListType: ChoiceListType) {
    return new HttpParams().set('type', choiceListType)
  }


  constructor(private actions$: Actions, private apiService: ApiService) {
  }

}
