import {Component, OnInit} from '@angular/core';
import {SideNavService} from "@shared/services/side-nav.service";
import {onMainContentChange} from "@shared/animations/animations";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  animations: [onMainContentChange]
})
export class ShellComponent implements OnInit {

  public onSideNavChange: boolean = false;
  currentUrl = ''
  routeHeaderMap = {
    '/admin/dashboard': 'Dashboard',
    '/admin/student': 'Students',
    '/admin/teacher': 'Teacher',
    '/admin/payment': 'Payment',
    '/admin/assessment/list': 'Assessment',
    '/admin/accounts': 'Accounts',
  }

  constructor(private _sidenavService: SideNavService, private router: Router) {
    this._sidenavService.sideNavState$.subscribe(res => {
      this.onSideNavChange = res;
    })
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({url}: any) => {
        this.currentUrl = url
      })
  }

  ngOnInit(): void {

  }

  onSidenavToggle(onSideNavChange: boolean) {
    this._sidenavService.sideNavState$.next(!onSideNavChange)
  }

}
