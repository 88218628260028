import {createReducer, on} from '@ngrx/store';
import * as ChoiceListActions from './choice-list.actions';
import {ChoiceList} from "@shared/model";
import {ChoiceListType} from "@shared/enum";

export const choiceListFeatureKey = 'choiceList';

export interface ChoiceListState {
  syllabus: ChoiceList[],
  grade: ChoiceList[],
  language: ChoiceList[],
  subject: ChoiceList[],
  qualification: ChoiceList[],
  studentType: ChoiceList[],
  paymentMethod: ChoiceList[],
  paymentAccount: ChoiceList[],
  difficulty: ChoiceList[],
  assessmentTheme: ChoiceList[],
  enquiryCategory: ChoiceList[],
  staff: ChoiceList[],
}

export const choiceListInitialState: ChoiceListState = {
  syllabus: [],
  grade: [],
  language: [],
  subject: [],
  qualification: [],
  studentType: [],
  paymentMethod: [],
  paymentAccount: [],
  difficulty: [],
  assessmentTheme: [],
  enquiryCategory:[],
  staff: []
};

export const choiceListReducer = createReducer(
  choiceListInitialState,
  on(ChoiceListActions.loadChoiceListSuccess, (state, action) => {
    const {choiceListType, data} = action

    switch (choiceListType) {
      case ChoiceListType.GRADE:
        state = {...state, grade: data}
        break;
      case ChoiceListType.SYLLABUS:
        state = {...state, syllabus: data}
        break;
      case ChoiceListType.STUDENT_TYPE:
        state = {...state, studentType: data}
        break;
      case ChoiceListType.QUALIFICATION:
        state = {...state, qualification: data}
        break;
      case ChoiceListType.LANGUAGE:
        state = {...state, language: data}
        break;
      case ChoiceListType.SUBJECT:
        state = {...state, subject: data}
        break;
      case ChoiceListType.PAYMENT_METHOD:
        state = {...state, paymentMethod: data}
        break;
      case ChoiceListType.PAYMENT_ACCOUNT:
        state = {...state, paymentAccount: data}
        break;
      case ChoiceListType.DIFFICULTY:
        state = {...state, difficulty: data}
        break;
      case ChoiceListType.ASSESSMENT_THEME:
        state = {...state, assessmentTheme: data}
        break;
      case ChoiceListType.ENQUIRY_CATEGORY:
        state = {...state, enquiryCategory: data}
        break;
      case ChoiceListType.STAFF:
        state = {...state, staff: data}
        break;

    }
    return state
  }),
  on(ChoiceListActions.loadChoiceListFailure, (state, action) => state),
);


