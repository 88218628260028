import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "@environment/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  private static setHeaders(): HttpHeaders {
    let headersConfig = {
      'content-type': 'application/json',
      Accept: 'application/json',
    };
    return new HttpHeaders(headersConfig);
  }

  get(path, params: HttpParams = new HttpParams(),) {
    return this.http
      .get(`${environment.api.url}/${path}`, {headers: ApiService.setHeaders(), params}).pipe(
        map((res: any) => (res))
      )
  }

  post(path, body) {
    return this.http
      .post(`${environment.api.url}/${path}`, JSON.stringify(body), {headers: ApiService.setHeaders()}).pipe(
        map((res: any) => (res))
      )
  }

  patch(path, body) {
    return this.http
      .patch(`${environment.api.url}/${path}`, JSON.stringify(body), {headers: ApiService.setHeaders()}).pipe(
        map((res: any) => (res))
      )
  }

  delete(path,) {
    return this.http
      .delete(`${environment.api.url}/${path}`,{headers: ApiService.setHeaders()}).pipe(
        map((res: any) => (res))
      )
  }
}
