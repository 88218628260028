<ng-container *ngIf="buttonType == 'regular'">
  <button
    class="regular"
    mat-button
    [matTooltip]="toolTip"
    [ngClass]="color"
    [disabled]="loading || disabled"
    (click)="onClick.emit()"
  >
    <span class="spinner" *ngIf="loading; else contentTpl">
      <mat-spinner [diameter]="20"></mat-spinner>
    </span>

  </button>
</ng-container>
<ng-container *ngIf="buttonType == 'icon'">
  <button class="icon" [ngClass]="color" mat-icon-button [matTooltip]="toolTip"
          (click)="onClick.emit()" [disabled]='disabled'>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </button>
</ng-container>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
