<mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">

  <mat-sidenav #drawer class="sidenav" fixedInViewport [mode]="'side'" opened>

    <app-left-menu></app-left-menu>
  </mat-sidenav>
  <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'">

    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="onSidenavToggle(onSideNavChange)">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="w-full flex place-content-between">
        <span class=" inline-block" routerLink="/">{{routeHeaderMap[currentUrl] || 'Q Live'}}</span>
        <mat-icon matBadge="0" matBadgeColor="warn">notifications_none</mat-icon>
      </div>
    </mat-toolbar>

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
