import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromChoiceList from './choice-list.reducer';

export const selectChoiceListState = createFeatureSelector<fromChoiceList.ChoiceListState>(
  fromChoiceList.choiceListFeatureKey
);

const getGrade = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.grade)
const getSyllabus = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.syllabus)
const getStudentType = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.studentType)
const getQualification = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.qualification)
const getLanguage = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.language)
const getSubject = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.subject)
const getPaymentMethod = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.paymentMethod)
const getPaymentAccount = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.paymentAccount)
const getDifficulty = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.difficulty)
const getAssessmentTheme = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.assessmentTheme)
const getEnquiryCategory = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.enquiryCategory)
const getStaff = createSelector(selectChoiceListState, (state: fromChoiceList.ChoiceListState) => state.staff)

export const choiceListQuery = {
  getGrade,
  getSyllabus,
  getStudentType,
  getQualification,
  getLanguage,
  getSubject,
  getPaymentMethod,
  getPaymentAccount,
  getDifficulty,
  getAssessmentTheme,
  getEnquiryCategory,
  getStaff
};
