import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from "@ngrx/effects";
import {ChoiceListEffects} from "./choice-list.effects";
import {StoreModule} from "@ngrx/store";
import {choiceListFeatureKey, choiceListInitialState, choiceListReducer} from "./choice-list.reducer";
import {ChoiceListFacade} from "./choice-list.facade";


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(choiceListFeatureKey, choiceListReducer, {
      initialState: choiceListInitialState
    }),
    EffectsModule.forFeature([ChoiceListEffects]),
  ],
  providers: [ChoiceListFacade]
})
export class ChoiceListStateModule {
}
